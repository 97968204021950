<template>
  <div class="appformMobile">
    <div class="title">
      <img
        src="../../assets/image/logo.png"
        style="width: 200px; margin-right: 12px"
      />
    </div>

    <div class="infoStyle">
      Extracurricular Activity Excellence (EAE) is a global accreditation
      platform that recognizes the outstanding achievements of pre-collegiate
      students in extracurricular activities.
    </div>
    <div class="infoStyle1">
      The EAE recognition application adopts a teacher recommendation system.
      Students need to complete EAE registration through teacher nomination to
      participate in the award recognition. Each category only accepts one
      student recommended by the teacher for recognition each year.
    </div>
    <div class="infoStyle1">
      Congratulations on being nominated and being qualified for the  23-24 EAE
      recognition. Please fill in the following information to complete the
      registration.
    </div>

    <div class="title1">Pre-registration</div>
    <el-form :model="form" :rules="formRules" ref="form" label-position="left">
      <div class="row">
        <div class="item">
          <el-form-item label="Register under" prop="school">
            <div class="input">
              <el-input v-model="form.school" :disabled="account_id"></el-input>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item
            label="Country Where Your School is Located"
            prop="country"
          >
            <div class="input">
              <el-input
                v-model="form.country"
                :disabled="account_id"
              ></el-input>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row" v-if="isCoordinator">
        <div class="item">
          <el-form-item label="Name of Your Full-time School">
            <div class="input">
              <el-input v-model="form.ownSchool"></el-input>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item
            label="Your Last Name in Native Language"
            prop="lastName"
          >
            <div class="input">
              <el-input v-model="form.lastName"></el-input>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item
            label="Your Given Name in Native Language"
            prop="givenName"
          >
            <div class="input">
              <el-input v-model="form.givenName"></el-input>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item
            label="Your Last Name in English on the Passport or the ID Card"
            prop="lastNameEn"
          >
            <div class="input">
              <el-input v-model="form.lastNameEn"></el-input>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item
            label="Your Given Name in English on the Passport or the ID Card"
            prop="givenNameEn"
          >
            <div class="input">
              <el-input v-model="form.givenNameEn"></el-input>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item label="Gender" prop="gender">
            <div class="input">
              <img
                src="../../assets/image/choose.svg"
                v-if="form.gender === 'Male'"
              />
              <img
                src="../../assets/image/nochoose.svg"
                v-else
                @click="form.gender = 'Male'"
              />Male
              <img
                src="../../assets/image/choose.svg"
                style="margin-left: 37px"
                v-if="form.gender === 'Female'"
              />
              <img
                src="../../assets/image/nochoose.svg"
                v-else
                style="margin-left: 37px"
                @click="form.gender = 'Female'"
              />Female
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item label="Graduation" prop="grade">
            <div class="input">
              <el-select
                v-model="form.grade"
                :popper-append-to-body="false"
                popper-class="customSelect"
              >
                <el-option
                  v-for="(year, index) in years"
                  :value="year"
                  :key="index"
                  :label="year"
                ></el-option>
              </el-select>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item label="Contact Number (Mobile)" prop="mobile">
            <div class="input">
              <el-input v-model="form.mobile"></el-input>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item label="Email Address" prop="email">
            <div class="input">
              <el-input v-model="form.email"></el-input>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item label="Guardian Contact Number (Mobile)" prop="gMobile">
            <div class="input">
              <el-input v-model="form.gMobile"></el-input>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item label="Guardian Email Address" prop="gEmail">
            <div class="input">
              <el-input v-model="form.gEmail"></el-input>
            </div>
          </el-form-item>
        </div>
      </div>

      <div class="row">
        <div class="item">
          <el-form-item label="Entry Type" prop="entry_type">
            <div class="input">
              <el-select v-model="form.entry_type">
                <el-option
                  value="team_story"
                  label="Team Storyline"
                ></el-option>
                <el-option
                  value="team_exp"
                  label="Team Work & Experience"
                ></el-option>
                <el-option
                  value="indie_story"
                  label="Individual Storyline"
                ></el-option>
                <el-option
                  value="indie_exp"
                  label="Individual Work & Experience"
                ></el-option>
              </el-select>
            </div>
          </el-form-item>
        </div>
      </div>

      <!-- <div class="row">
                  <div class="item">
                      <el-form-item label="Referrer’s Name">
                          <div class="input">
                              <el-input v-model="form.adiviserName" disabled></el-input>
                          </div>
                      </el-form-item>
                  </div>
              </div> -->
      <!-- <div class="row">
                <div class="item">
                      <el-form-item label="Adviser’s Affiliation">
                          <div class="input">
                              <el-input v-model="form.adiviserAff" disabled></el-input>
                          </div>
                      </el-form-item>
                  </div>
              </div> -->
      <!-- <div class="row">
                  <div class="item">
                      <el-form-item label="Adviser’s Phone Number">
                          <div class="input">
                              <el-input v-model="form.adiviserMobile" disabled></el-input>
                          </div>
                      </el-form-item>
                  </div>
              </div> -->
      <div class="row">
        <div class="item">
          <el-form-item label="Referrer’s Email Address">
            <div class="input">
              <el-input v-model="form.adiviserEmail" disabled></el-input>
            </div>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div class="submit">
      <div class="submitBtn" @click="submit">Submit</div>
    </div>
  </div>
</template>

<script>
import {
  saveAppForm,
  addStudentInDatabaseV2,
  getAccount,
  getContactInfo,
} from "../../api/eae";
import "../../assets/common/common.css";

export default {
  data() {
    return {
      account_id: this.$route.query.account_id,
      entry_id: this.$route.query.entry_id,
      contact_id: this.$route.query.contact_id,
      category_id: this.$route.query.category_id,
      form: {
        ready: "",
        way: "",
        school: "",
        country: "",
        lastName: "",
        givenName: "",
        lastNameEn: "",
        givenNameEn: "",
        gender: "Male",
        grade: "",
        mobile: "",
        email: "",
        gMobile: "",
        gEmail: "",
        adiviserName: "",
        adiviserAff: "",
        adiviserMobile: "",
        adiviserEmail: "",
        ownSchool: "",
      },
      entry: {},
      showEntry: false,
      isCoordinator: false,
      formRules: {
        // ready: [
        //   { required: true, message: 'Please choose', trigger: 'change' }
        // ],
        // way: [
        //   { required: true, message: 'Please choose', trigger: 'change' }
        // ],
        school: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        lastName: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        givenName: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        lastNameEn: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        givenNameEn: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        gender: [
          { required: true, message: "Please choose", trigger: "change" },
        ],
        grade: [
          { required: true, message: "Please choose", trigger: "change" },
        ],
        mobile: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        email: [{ required: true, message: "Please input", trigger: "change" }],
        gMobile: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        gEmail: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        entry_type: [
          { required: true, message: "Please choose", trigger: "change" },
        ],
      },
      account: {},
      years: [],
    };
  },
  mounted() {
    let current_year = new Date().getFullYear();
    let start_year = current_year;

    for (let i = 0; i < 6; i++) {
      let year = start_year + i;
      this.years.push(year);
    }

    if (this.$route.query && this.$route.query.unit_id) {
      let uid = this.$route.query.unit_id;
      if (uid != "") {
        this.account_id = this.$route.query.unit_id;
      }
    }

    if (this.$route.query && this.$route.query.share_id) {
      let cont_id = this.$route.query.share_id;
      if (cont_id != "") {
        this.contact_id = this.$route.query.share_id;
      }
    }

    if (this.account_id) {
      getAccount(this.account_id).then((res) => {
        if (res.data.code === 0) {
          this.account = res.data.data;
          this.form.school = res.data.data.account_name;
          this.form.country = res.data.data.account_country;

          if (res.data.data.account_type == "教育机构") {
            this.isCoordinator = true;
          }
        }
      });
    }

    if (this.contact_id) {
      getContactInfo(this.contact_id).then((res) => {
        if (res.data.code === 0) {
          this.form.adiviserName =
            res.data.data.contact_lastName + res.data.data.contact_givenName;
          this.form.adiviserEmail = res.data.data.contact_email;
        }
      });
    }

    this.showEntry = false;

    // if (this.entry_id) {
    //   this.showEntry = true

    //   getMREntryById(this.entry_id).then(res => {
    //     if (res.data.code === 0) {
    //       this.entry = res.data.data
    //     }
    //   })
    // }
  },
  methods: {
    submit() {
      this.$refs.form.validate((val) => {
        if (val) {
          if (
            this.account_id &&
            this.account_id != "" &&
            this.contact_id &&
            this.contact_id != "" &&
            this.category_id &&
            this.category_id != ""
          ) {
            addStudentInDatabaseV2(
              this.account_id,
              this.contact_id,
              this.category_id,
              this.form.entry_type,
              this.form.lastName,
              this.form.givenName,
              this.form.lastNameEn,
              this.form.givenNameEn,
              this.form.gender,
              this.form.grade,
              "impact",
              this.form.mobile,
              this.form.email,
              this.form.ownSchool
            ).then((res) => {
              if (res.data.code === 0) {
                this.$router.replace({
                  path: "/registrationsuccess",
                });

                // this.$confirm('Please visit https://my.eae.online/ through a desktop broswer to log in with the email address you just entered. The initial password is "impact".', 'Successful Registration', {
                //   confirmButtonText: 'OK',
                //   cancelButtonText: 'Cancel',
                //   type: 'success',
                //   customClass: 'mobileConfirm'
                // })
              } else {
                this.$message.error(res.data.msg);
              }
            });
          } else {
            saveAppForm(this.form).then((res) => {
              if (res.data) {
                this.$confirm("Submit Successful", "Tips", {
                  confirmButtonText: "OK",
                  cancelButtonText: "Cancel",
                  type: "success",
                  customClass: "mobileConfirm",
                });
              }
            });
          }
        } else {
          this.$confirm("Please fill in the required information", "Tips", {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
            customClass: "mobileConfirm",
          });
        }
      });
    },
  },
};
</script>

  <style scoped lang="scss">
.appformMobile {
  padding: 20px;
  box-sizing: border-box;
  width: 100vw;
  font-family: "DDINAlternateRegular";
  overflow-y: scroll;
  background: #fff;
  .title {
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #ff6450;
    font-size: 22px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
  }
  .title1 {
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #ff6450;
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 50px;
    //   display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
  }
  .infoStyle {
    width: 100%;
    line-height: 150%;
    margin-bottom: 20px;
    text-align: justify;
  }
  .infoStyle1 {
    width: 100%;
    line-height: 150%;
    // margin-bottom: 20px;
    text-align: justify;
    margin-top: 10px;
    // background-color: red;
    // text-align-last: justify;
    // text-justify: distribute-all-lines;
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .item {
      width: 480px;
      .label {
        font-size: 16px;
        font-family: Alibaba PuHuiTi 2-55 Regular, Alibaba PuHuiTi 20;
        font-weight: normal;
        color: #666666;
      }
      .input {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 18px;

        font-family: Alibaba PuHuiTi 2-55 Regular, Alibaba PuHuiTi 20;
        font-weight: normal;
        color: #333333;
        img {
          margin-right: 24px;
          cursor: pointer;
        }
      }
    }
  }

  .submit {
    .submitBtn {
      width: 90%;
      height: 50px;
      background: #0e4890;
      border-radius: 25px;
      text-align: center;
      line-height: 50px;
      color: #fff;
      font-size: 20px;
      margin: 0 auto;
      margin-top: 84px;
      font-weight: bold;
      cursor: pointer;
    }
    margin-bottom: 50px;
  }
}
</style>
  <style lang="scss">
.appformMobile {
  .el-input__inner {
    border-radius: 6px;
    border: 2px solid #717171;
    font-size: 18px;
  }
  .el-form-item__label {
    font-size: 16px;
    font-family: Alibaba PuHuiTi 2-55 Regular, Alibaba PuHuiTi 20;
    font-weight: normal;
    color: #666666;
    line-height: 30px;
    margin-bottom: 6px;
  }
  .customSelect {
    width: 100%;
  }
}

.mobileConfirm {
  width: 80%;
  .el-message-box__container {
    display: flex;
    .el-message-box__status {
      position: relative;
      flex-shrink: 0;
      transform: none;
    }
    .el-message-box__message {
      padding-left: 10px;
    }
  }
}
</style>
